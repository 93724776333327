<template>
  <div class="registration-step-1-block">
    <div class="login-block-container">
      <div class="reg-step-form">
        <h3>{{ $lang.app.reset_account }}</h3>
        <p>{{ $lang.app.reset_account_des }}</p>
        <Form @submit="submit" :validation-schema="schema">
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.surname }} <i>*</i></label>
            <div class="reg-step-1-select-container with-letter-buttons">
              <Field name="lastname" type="text" autocomplete="off"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="forgotEmailForm.lastname"/>
              <ExtraKyrgyzLetters @change="forgotEmailForm.lastname += $event"/>
            </div>
            <ErrorMessage name="lastname" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.citizenship }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="citizenShip"
                     v-model="forgotEmailForm.citizenShip">
                <option v-for="citizenShip in helper.citizenShips" :key="citizenShip.value"
                        :value="citizenShip.value">{{ citizenShip.label}}</option>
              </Field>
            </div>
            <ErrorMessage name="citizenShip" class="recovery-label-error" />
          </div>
          <div v-if="forgotEmailForm.citizenShip === 'KG'" class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.passport_seria }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="passportRange"
                     v-model="forgotEmailForm.passportRange">
                <option v-for="passportRange in helper.passportRanges" :key="passportRange"
                        :value="passportRange">{{ passportRange }}</option>
              </Field>
            </div>
            <ErrorMessage name="passportRange" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.passport_number }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="passportId" type="text" autocomplete="off"
                     v-maska="forgotEmailForm.citizenShip === 'KG' ? '#######' : ''"
                     v-model="forgotEmailForm.passportId"/>
              <div class="tooltip-target">
                <i class="bi-question-circle"></i>
                <div class="tooltip-content tooltip-content-small">
                  <p>{{ $lang.app.passport_number_des }}</p>
                  <p>{{ $lang.app.passport_number_example }}</p>
                  <div class="passport-images">
                    <div class="passport-image">
                      <img src="../../assets/images/id-2017.png" alt="">
                      <span>{{ $lang.app.id_card }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ErrorMessage name="passportId" class="recovery-label-error" />
          </div>
          <div class="login-control">
            <button class="transparent-button" @click="$router.push('/auth/login-parent')">{{ $lang.app.cancel }}</button>
            <button class="blue-button" type="submit">{{ $lang.app.check }}</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { maska } from 'maska'
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import ExtraKyrgyzLetters from '../../components/ExtraKyrgyzLetters.vue'
export default {
  name: 'ForgotEmail',
  components: {
    Field,
    Form,
    ErrorMessage,
    ExtraKyrgyzLetters,
  },
  directives: { maska },
  data() {
    return {
      helper: {
        citizenShips: [
          {
            label: this.$lang.app.citizen_kg,
            value: 'KG'
          },
          {
            label: this.$lang.app.citizen_other,
            value: 'FOREIGN'
          }
        ],
        passportRanges: ['AN', 'ID'],
      },
      forgotEmailForm: {
        citizenShip: null,
        lastname: '',
        passportRange: null,
        passportId: '',
      },
      schema: yup.object({
        citizenShip: yup.mixed().required(),
        lastname: yup.string().required(),
        passportRange: yup.mixed().when('citizenShip', {
          is: 'KG',
          then: yup.mixed().required(),
          otherwise: yup.mixed().nullable(),
        }),
        passportId: yup.mixed().when('citizenShip', {
          is: 'KG',
          then: yup.string().required().min(7),
          otherwise: yup.string().required(),
        }),
      }),
    }
  },
  methods: {
    submit(values, actions) {
      this.$axios.post('/auth/restore-password/passport', this.forgotEmailForm)
      .then(({data}) => {
        this.$router.push(`/auth/send-to-email?surname=${values.lastname}&email=${data.message}`)
      }).catch(error => {
        if(error.response.status === 400) {
          actions.setErrors({
            passportId: error.response.data.message,
          });
        }
      })
    }
  }
}
</script>
<style>
@import '../../assets/styles/form.css';
@import '../../assets/styles/auth.css';
</style>